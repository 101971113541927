<template>
  <div class="container-fluid subscription-products">
    <div class="d-flex justify-content-center flex-wrap align-items-end">
      <ProductSkeleton />
      <ProductSkeleton is-premium />
      <ProductSkeleton />
    </div>
  </div>
</template>
<script>
import ProductSkeleton from './ProductSkeleton'

export default {
  components: {
    ProductSkeleton,
  },
}
</script>

<style lang="scss" scoped>
@include media-breakpoint-up(md) {
  .subscription-products {
    padding-bottom: 72px;
  }
}
</style>
