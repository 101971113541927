<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    data-nw="subscription-product"
    :class="[
      'subscription-product d-flex flex-column p-32 pb-40 mr-8 ml-8 mr-lg-16 ml-lg-16 mb-24',
      { 'subscription-product--premium': isPremium },
    ]"
  >
    <div>
      <p v-if="isPremium" class="red-label fw-semibold mb-0">
        {{ $label('user.signUp.mostChosen') }}
      </p>
      <h3 v-if="isPremium" class="mb-0">
        {{ product.name }}
      </h3>
      <h4 v-else class="mb-0">
        {{ product.name }}
      </h4>
    </div>

    <div class="mt-24 mt-lg-32">
      <p class="subscription-price fw-semibold mb-0">{{ product.priceText }}</p>
      <p class="text-large fw-normal mb-0">/{{ $label('common.month') }}</p>
    </div>

    <ul
      class="mt-24 mt-lg-32 mb-32 mb-lg-48 styled-list"
      v-html="product.discountText"
    ></ul>

    <BaseButton
      class="card-button"
      :data-nw="isPremium ? 'premium-product' : null"
      :disabled="addingToCart"
      :class="{ loading: addingToCart }"
      :variant="buttonVariant"
      @click="select(product)"
    >
      {{ $label(isPremium ? preSelectedBtnLabel : selectBtnLabel) }}
    </BaseButton>
  </div>
</template>

<script>
import currencyFormatter from '~/utils/currencyFormatter'

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    showSelectBtn: {
      type: Boolean,
      default: true,
    },
    preSelectedBtnLabel: {
      type: String,
      default: 'subscription.choose.preSelectedBtn',
    },
    selectBtnLabel: {
      type: String,
      default: 'subscription.choose.selectBtn',
    },
    onSelect: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      addingToCart: false,
    }
  },
  computed: {
    monthlyPrice() {
      return currencyFormatter(this.product.monthlyPrice)
    },
    isPremium() {
      return this.product.isPreselected
    },
    buttonVariant() {
      return this.isPremium ? 'call-to-action' : 'secondary'
    },
  },
  methods: {
    async select(product) {
      this.addingToCart = true

      await this.onSelect(product)

      this.addingToCart = false
    },
  },
}
</script>

<style lang="scss" scoped>
.subscription-product {
  background-color: $white;
  border-radius: 16px;
  width: 342px;
  box-shadow: 1px 4px 24px 0px rgba(0, 0, 0, 0.05);
}

.red-label {
  color: $red;
}

.text-large {
  color: $mid-grey;
}

.subscription-price {
  font-size: 27px;
  line-height: 33.75px;
}

@include media-breakpoint-down(md) {
  .subscription-product {
    order: 2;
  }

  .subscription-product--premium {
    order: 1;
  }
}
</style>
